.gameSelectHeader {
    font-size: xxx-large;
    font-family: Arial;
    font-weight: bolder;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 0px #000000;
    padding-bottom: 50px;
}

.payoutButtons {
    cursor: pointer;
    padding: 50px;
    max-width: 100%;
    width:240px;
    height: 240px;
}

.navImageButton {
    cursor: pointer;
    width:383px;
    height: 88px;
}

.payoutText {
    font-weight: bolder;
    font-size: large;
}

.gameSelectText {
    font-size: xx-large;
}

.gameSelectInfo {
    font-size: large;
    font-weight: 500;
}

.gameSelectNumberText {
    font-weight: bolder;
    font-size: xx-large;
    color:white;
}

.modalCarouselRight {
    position: absolute;
    top: 400px;
    right: -120px;
    cursor: pointer;
    z-index: 20;
}

.modalCarouselLeft {
    position: absolute;
    top: 400px;
    right: 800px;
    cursor: pointer;
    z-index: 20;
}

.gameSelectBackground {
    background-image: url("../../images/GameBackground.png");
    background-size: contain;
}

.gameDetailsName {
    font-weight:bolder;
    font-size:xxx-large;
}

.gameDetailsIcon  {
    max-height: 320px;
    width: auto;
}

.gameIcon {
    padding: 10px 20px 10px 20px;
    width: 180;
    height: 160px;
    cursor: pointer;
}

.gameSelectCheckMark {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.radioStyle {
  font-size: 14px;
  font-weight: bold;
}

.selectionReviewGameLabel {
    font-weight: bolder;
    padding-left:4px;
}

.selectionReviewGameText {
    font-weight: bolder;
    padding-left: 4px;
}

.selectionReviewIcon {
    width: 140px;
    height: 140px;
    padding: 10px;
    cursor: pointer;
}

.selectionReviewEditText {
    font-size: x-large;
    font-family: Arial;
    font-weight: bolder;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 0px #000000;
    cursor: pointer;
}

.locationStatusText {
    font-size: large;
    font-weight: bolder;
}

.locationStatusTextCompleteColor {
    color:limegreen
}

.addGamesBtn {
  margin-left: 10px;
}

.selectionReviewRemoveButton {
    cursor: pointer;
    width: 70px;
    height: 62px;
}

.textBackground {
    background-color: white;
    margin-bottom: 8px;
}

.gameDetailsMainDiv {
    background-color: #E7E7E7;
    outline-width: medium;
    border-radius: 30px/30px;
    border: solid #FFFFFF;
    border-width: thick;
    padding: 20px;
}

.gameDetailsText {
    font-size:x-large;
}

.gameSelectOptionsText {
    padding-top: 20px;
    padding-bottom: 20px
}

.gameDetailsHeader {
    font-size: xxx-large;
    color: #000000;
    text-shadow: 1px 0 #000000;
    letter-spacing: 1px;
    font-weight: bold;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
}

.ant-collapse-header {
    padding-top: 10px;
}

.floatRight {
    float:right
}

.pad5TopBottom {
    padding-top: 5px;
    padding-bottom: 5px;
}

.pad20Bottom {
  padding-bottom: 20px;
}

