.gameViewBackground {
  background-image: url("../../images/GameBackground.png");
  background-size: contain;
}

.gameViewHeader {
  font-size: xxx-large;
  font-family: Arial;
  font-weight: bolder;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 0px black;
  padding-bottom: 50px;
}

.textCenter {
  font-weight:bolder;
  font-size:xxx-large;
  text-align: center;
}

.gameIcon {
  padding: 10px 20px 10px 20px;
  width: 180;
  height: 160px;
  cursor: pointer;
}