.listing {
  margin: auto;
  max-width: 300px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ant-list-item {
  border-bottom: 0px !important;
  font-weight: bold !important;
}
.ant-list-item.ant-list-item-no-flex {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}