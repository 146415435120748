@font-face {
  font-family: 'pg_icons';
  src        : url('./fonts/pg_icons.eot?599dxp');
  src        : url('./fonts/pg_icons.eot?599dxp#iefix') format('embedded-opentype'),
          url('./fonts/pg_icons.woff2?599dxp') format('woff2'),
          url('./fonts/pg_icons.ttf?599dxp') format('truetype'),
          url('./fonts/pg_icons.woff?599dxp') format('woff'),
          url('./fonts/pg_icons.svg?599dxp#icomoon') format('svg');
      font-weight: normal;
  font-style : normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family   : 'pg_icons' !important;
  speak         : none;
  font-style    : normal;
  font-weight   : normal;
  font-variant  : normal;
  text-transform: none;
  line-height   : 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next
{
  width : 150px;
  height : 150px;
  z-index : 2;
}

.icon-charity:before {
  content: "\e900";
}

.icon-charity-filled:before {
  content: "\e901";
}

.icon-minus:before {
  content: "\e90e";
}

.icon-zone:before {
  content: "\e91f";
}

.icon-zone-filled:before {
  content: "\e920";
}

.icon-add:before {
  content: "\e90f";
}

.icon-billing:before {
  content: "\e910";
}

.icon-calendar:before {
  content: "\e911";
}

.icon-cash:before {
  content: "\e912";
}

.icon-checkmark:before {
  content: "\e913";
}

.icon-contacts:before {
  content: "\e914";
}

.icon-dashboard:before {
  content: "\e915";
}

.icon-deal:before {
  content: "\e916";
}

.icon-download:before {
  content: "\e917";
}

.icon-edit:before {
  content: "\e918";
}

.icon-games:before {
  content: "\e919";
}

.icon-graph:before {
  content: "\e91a";
}

.icon-hide:before {
  content: "\e91b";
}

.icon-map:before {
  content: "\e91c";
}

.icon-reports:before {
  content: "\e91d";
}

.icon-warning:before {
  content: "\e91e";
}

.icon-chev-down:before {
  content: "\e902";
}

.icon-close:before {
  content: "\e903";
}

.icon-help:before {
  content: "\e904";
}

.icon-location:before {
  content: "\e905";
}

.icon-location-filled:before {
  content: "\e906";
}

.icon-no-data:before {
  content: "\e907";
}

.icon-notifications:before {
  content: "\e908";
}

.icon-remove:before {
  content: "\e909";
}

.icon-upload-photo:before {
  content: "\e90a";
}

.icon-user:before {
  content: "\e90b";
}

.icon-user-bigger:before {
  content: "\e90b";
  font-size: 2em;
}

.icon-user-role:before {
  content: "\e90c";
}

.icon-user-role-filled:before {
  content: "\e90d";
}

.icon-distributor:before {
content: "\e910";
}

.icon-star:before {
content:"\e006";
}


$blue-light              : #e8f5ff;
$grey-light              : #FAFAFA;
$grey-medium             : #E4E5EF;
$grey-dark               : #A6ADC9;
$grey-darker             : #656C8A;
$white                   : #fff;
$primary                 : #D41C33;
$danger                  : #D75656;
$success                 : green;
$background-gray         : #222328;
$black-light             : rgba(51, 51, 51, 0.85);
$black                   : rgba(0, 0, 0, 0.85);
$information-phone-mobile: "only screen and (max-width : 576px)";
$information-tab         : "(min-width: 577px) and (max-width : 768px)";
$information-laptop      : "(min-width: 769px) and (max-width : 1024px)";

// $information-phone-mobile: "only screen and (max-width : 480px)";
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mr-8 {
  margin-right: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-26 {
  margin-left: 26px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-16 {
margin-top: 16px !important;
}

.t-grey-light {
  color: $grey-light;
}

.t-grey-dark {
  color: $grey-dark;
}

.t-grey-darker {
  color: $grey-darker;
}

.t-black-light {
  color: $black-light;
}

.t-black {
  color: $black;
}

.t-red {
  color: $danger;
}

.t-green {
  color: $success;
}

.helper-text {
  font-size  : 10px;
  line-height: 14px;
  color      : $grey-darker;
}

.bor-grey-med {
  border-right: 1px solid $grey-medium
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.regular {
  font-weight: normal;
}

.bold {
  font-weight: 500
}


.link-primary {
  color     : $primary !important;
  cursor    : pointer;
  transition: opacity .8s ease-in;
}

.link-danger {
  color     : $danger;
  cursor    : pointer;
  transition: opacity .8s ease-in;
}

.link-primary:hover,
.link-danger:hover {
  opacity: 0.8;
}

.ant-btn-primary {
background-color: $primary;
border-color: $primary;
}

.ant-btn-primary:hover {
background-color: $background-gray;
border-color: $primary;
}

body {
  font-size: 14px;
  color    : $black;
}

p {
  margin-bottom: 8px !important;
}

h1 {
  font-size: 24px;
}

.empty-state {
  background: $white;
  color     : $grey-dark;
  text-align: center;
  padding   : 40px 0;
  font-size : 18px;

  i {
      font-size     : 24px;
      vertical-align: sub;
  }
}

.failed-state {
  background: $white;
  color     : $danger;
  text-align: center;
  padding   : 40px 0;
  font-size : 18px;

  i {
      font-size     : 24px;
      vertical-align: sub;
      margin-right: 10px;
  }

  button {
    margin-left: 10px;
  }
}

.login-layout {
  background: $background-gray !important;
}

.ant-avatar {
background-color: $primary;
}

.pg-header {
  &.ant-layout-header {
      background  : $white;
      padding     : 0 24px;
      z-index     : 10;
      width       : 100%;
      left        : 0;
      padding-left: 24px;
      box-shadow  : 0 1px 4px 0 rgba(0, 21, 41, 0.12);

      @media #{$information-phone-mobile} {
          padding-left: 24px;
      }
  }

  .page-title {
      display: inline-block;

      .ant-breadcrumb {
          &>span {

              .ant-breadcrumb-link,
              .ant-breadcrumb-separator {
                  color    : $grey-medium;
                  font-size: 18px;
              }

              &:last-child {
                  .ant-breadcrumb-link {
                      color      : $black;
                      font-weight: bold;
                  }
              }
          }

          @media #{$information-phone-mobile} {
              line-height: 16px;
          }

      }

      @media #{$information-phone-mobile} {
          font-size     : 20px;
          vertical-align: middle;
      }
  }

  ul {
      margin-bottom: 0;

      li {
          cursor : pointer;
          display: inline-block;
          padding: 0 16px;
          color  : $grey-dark;

          @media #{$information-phone-mobile} {
              padding: 0 4px;
          }

          .anticon {
              font-size     : 24px;
              vertical-align: middle;
          }

          .ant-avatar {
              border: 1px solid $grey-dark;
          }

          &:first-child {
              padding-left: 0;
          }

          &:last-child {
              padding-right: 0;
          }
      }
  }

  .ant-badge-count {
      height     : 18px;
      min-width  : 18px;
      line-height: 16px;
  }
}

.pg-sider {
  overflow-y: scroll;
  word-wrap: break-word;
  min-height: 100vh;
  min-width: 300px;
  position  : relative !important;
  left      : 0;
  z-index   : 11;

  &.ant-layout-sider,
  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
      background: $background-gray;
  }

  .logo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding   : 16px 0;

    .logo-separator {
      height: 56px;
      border-left: 1px solid rgba(255, 255, 255, 0.65);
    }
  }

  .win-with-arrow {
    position: absolute;
    margin: 5%;
    width: 90%;
    bottom: 0;
    display: none;
  }

  .hide-win-with-arrow {
    position: absolute;
    margin: 5%;
    width: 90%;
    bottom: 0;
    display: none;
  }

  @media (min-height: 1000px) {
    .win-with-arrow {
      display: inline-block;
    }

    .hide-win-with-arrow {
      display: inline-block;
    }
  }

@media (min-height: 730px) {
  .win-with-arrow {
    display: inline-block;
  }
}

  .version {
      text-align: center;
      padding: 16px 0;
      color: white;
  }

  .ant-menu-inline .ant-menu-item {
      font-size    : 15px;
      font-weight  : bold;
      text-align: left;

      [class^="icon-"],
      [class*=" icon-"] {
          font-size     : 25px;
          vertical-align: sub;
          margin-right  : 5px;
      }
  }

  .ant-menu-submenu.ant-menu-submenu-inline {
      .ant-menu-item {
          padding-left: 28px!important;
      }

      .ant-menu-submenu-title {
          font-size  : 16px;
          font-weight: bold;
      }
  }

  .ant-menu-item-selected {
    background-color: $primary !important;
  }

  @media #{$information-phone-mobile} {
      position: absolute !important;
      height  : 100%;
      z-index : 15;
      overflow: visible;

      .ant-layout-sider-zero-width-trigger {
          height     : 28px;
          line-height: 28px;
      }
  }
}

.main-layout {
  margin-left: 10px;
  overflow   : hidden;

  @media #{$information-phone-mobile} {
      margin-left: auto;
  }
}

.pg-layout-content {
  display: flex;
  flex-direction: column;
}

.pg-container {
  overflow-y: scroll;
  overflow-x: auto;
  height    : calc(100vh - 64px);

  &.container,
  .container {
      padding      : 0 24px;
      margin-bottom: 24px;
  }

  &.page-container {
      padding   : 0px 0px 0px 0px;
      margin-top: 5px;
  }

  &.no-padding-page-container {
      margin-top: 5px;
      padding   : 0;
  }
}

.ant-tag {
  cursor: default !important;
}

.ant-card {

  &.ant-card-wider-padding .ant-card-head,
  &.ant-card-wider-padding .ant-card-body {
      padding-left : 24px !important;
      padding-right: 24px !important;
  }

  .ant-card-head-wrapper {
      @media #{$information-phone-mobile} {
          display: block;
      }

      .ant-card-head-title,
      .ant-card-extra {
          padding: 16px 0 12px 0;
      }

      .ant-card-head-title {
          font-size    : 20px;
          text-overflow: initial !important;
          white-space  : normal !important;
          flex         : none;

          .ant-select {
              font-weight: 300;
          }
      }
  }

  &.ant-card-wider-padding .ant-card-body,
  .ant-card-body {
      padding-top: 12px !important;
  }

  &.hide-border .ant-card-head {
      border-width      : 0 !important;
      -webkit-transition: border-width 0.2s linear;
      -moz-transition   : border-width 0.2s linear;
      -o-transition     : border-width 0.2s linear;
      transition        : border-width 0.2s linear;
  }

  &.show-border .ant-card-head {
      border-width      : 1px !important;
      -webkit-transition: border-width 0.6s linear;
      -moz-transition   : border-width 0.6s linear;
      -o-transition     : border-width 0.6s linear;
      transition        : border-width 0.6s linear;
  }

  &.no-header-border {
      .ant-card-head {
          border-bottom: none;
      }
  }

  &.no-card-padding {
      .ant-card-head {
          padding: 0 !important;
      }
      .ant-card-body {
          padding: 0 !important;
      }
  }

  &.no-body-padding {
      .ant-card-body {
          padding: 0 !important;

          .ant-form {
              padding: 0 24px;
          }

          .ant-list-item {
              padding: 12px 24px;

              &.active {
                  background: #e7eef4;
              }
          }
      }
  }

  &.card-wrap-header .ant-card-head-title {
      display: flex !important;
      flex-wrap: wrap !important;
      flex: auto;
      align-items: baseline;
  }

  &.card-wrap-header .ant-card-head-wrapper {
      flex-wrap: wrap;
  }
}

.ant-collapse {
  background: $white !important;
  border    : none !important;

  &>.ant-collapse-item>.ant-collapse-header {
      .arrow {
          right: 16px;
          left : auto !important;
      }
  }

  & .ant-collapse-content>.ant-collapse-content-box {
      padding: 16px 24px !important;
  }
}

.antdTableExpandHidden .ant-table-row-expand-icon-cell span {
  display: none;
}

.ant-list {
  &.multiline-list {
      .ant-list-item {
          position: relative;
      }

      .ant-list-item-action {
          position: absolute;
          right   : 14px;
          top     : 10px;
      }
  }

  &.open-tree {
      .ant-list-item-meta-title .ant-tree li .ant-tree-node-content-wrapper {
          cursor: default !important;
      }
  }

  .ant-list-item-content {
      -webkit-box-pack       : start;
      -webkit-justify-content: left;
      -ms-flex-pack          : start;
      justify-content        : left;
  }

  .ant-list-item-action-split {
      display: none;
  }

  .ant-list-item-meta-title {
      margin-bottom: 0;

      .ant-tree {
          &>li {
              padding: 0;

              .ant-tree-iconEle {
                  font-size     : 16px;
                  vertical-align: middle;
                  margin-right  : 8px;
              }

              ul.ant-tree-child-tree {
                  li.ant-tree-treenode-switcher-open {
                      padding-left: 8px;
                  }
              }
          }
      }

      .ant-tree li .ant-tree-node-content-wrapper {
          padding: 0;
      }

      .ant-tree li span.ant-tree-switcher {
          display: none;
      }

      .ant-tree li .ant-tree-node-content-wrapper:hover,
      .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
          background: transparent !important;
      }
  }

  .ant-list-item-action {
      margin-left: 0;

      i {
          font-size     : 16px;
          vertical-align: middle;
      }
  }
}

.ant-progress {
  .ant-progress-text {
      font-size: 10px;
      color    : $black !important;
  }
}

.ant-select-tree {
  li {

      span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon,
      span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
          font-size: 18px !important;
      }
  }
}

.permissions-accordian {
  background: $white;
  border    : none;

  .ant-collapse-item {
      border-radius: 4px;
      margin-bottom: 24px;
      overflow     : hidden;
      border       : 1px solid $grey-medium;

      .ant-collapse-header {
          background: $grey-light;
      }

      .ant-collapse-arrow {
          left: 10px;
      }
  }
}

.ant-tree.block-children {
  li .ant-tree-node-content-wrapper:hover {
      background-color: transparent;
  }

  .ant-tree-node-content-wrapper {
      i.icon-remove {
          margin-left: 15px;
      }
  }
}

.zone-tree-container {
  background: $white;

  .ant-tree {
      li {
          padding-top   : 12px;
          padding-bottom: 12px;

          .ant-tree-node-content-wrapper {
              padding: 0;

              &.ant-tree-node-selected,
              &:hover {
                  background: transparent !important;
              }
          }
      }

      &>li {
          border-bottom: 1px solid $grey-medium;

          &:last-child {
              border-bottom: none;
          }

          .ant-tree-iconEle {
              font-size     : 16px;
              vertical-align: middle;
              margin-right  : 8px;
          }

          .ant-tree-title {
              font-weight: bold;
          }
      }

      ul.ant-tree-child-tree {
          background: $white;
          margin-top: 12px;
      }

      li {

          span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
          span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon {
              font-size: 18px !important;
          }
      }
  }

  .ant-tree-treenode-selected {
      &.ant-tree-treenode-switcher-open {
          padding-bottom: 0;
      }

      background: $blue-light;
  }
}

.zone-color-header-tag {
  border-radius : 50%;
  height        : 24px;
  width         : 24px;
  vertical-align: text-top;
}

.image-upload-container {
  position: relative;
  display : inline-block;

  &:hover {
      .action-btn {
          opacity   : 1;
          visibility: visible;

          .link-danger {
              visibility: visible !important;
          }
      }
  }

  .action-btn {
      opacity         : 0;
      visibility      : hidden;
      top             : 0;
      left            : 0;
      right           : 0;
      bottom          : 0;
      background-color: rgba(0, 0, 0, .7);
      position        : absolute;
      border-radius   : 50%;
      transition      : all 0.4s linear;
      text-align      : center;

      .link-danger {
          visibility: hidden;
          position  : relative;
          top       : 35px;
      }
  }

  .ant-upload.ant-upload-select-picture-card {
      border    : none;
      background: $white;
      height    : 0;
      width     : 0;
      padding   : 0;
      position  : absolute;

      .image-upload {
          background   : $primary;
          border-radius: 50%;
          font-size    : 22px;
          position     : absolute;
          top          : 32px;
          left         : 88px;
          height       : 40px;
          width        : 40px;
          text-align   : center;
          color        : $white;
          font-size    : 30px;
          z-index      : 10;
      }
  }
}

.pg-dashboard-container {
  overflow-y: scroll;
  overflow-x: hidden;
  padding   : 0 5px 5px 0;
}

.pg-reports-container {
  overflow-y: scroll;
  overflow-x: auto;
  padding   : 5px 5px 2px 5px;
}

.pg-billing-table {
overflow-y: scroll;
overflow-x: auto;
padding   : 24px 24px 0 24px;
background-color: white;
}

.pg-billing-footer {
display: flex;
align-items: center;
align-content: center;
padding: 0px;
height    : 50px;
position: -webkit-sticky;
position: sticky;
bottom: 0px;
background-color: white;
padding-right: 12px;
padding-left: 12px;
}

.pg-ssrs-reports-container {
  display: flex;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: auto;
  padding   : 5px 5px 0 5px;
}

.dashboard-filter-card,
.reports-filter-card {
  z-index   : 1;
  margin-top: 5px !important;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, .12);

  .ant-form-item {
      margin-bottom: 0 !important;
  }

  .ant-form-item-label {
      text-align : left !important;
      line-height: 32px !important;
  }

  .ant-form-item-control {
      line-height: 32px !important;
  }

  .has-success {
      .ant-select-selection {
          border-color: $primary;
      }

      .ant-select-arrow {
          color: $primary;
      }
  }

  @media #{$information-phone-mobile} {
      .ant-btn {
          margin-top: 20px;
          display   : inherit;
      }
  }

  @media #{$information-tab} {
      .ant-btn {
          margin-top: 32px
      }
  }
}

.dashboard-map-stats-container {
  overflow-y: auto;

  h3 {
      margin-bottom: 0;
  }

  .ant-list-item {
      padding           : 8px 24px !important;
      cursor            : pointer;
      -webkit-transition: background 0.4s ease-in-out;
      -moz-transition   : background 0.4s ease-in-out;
      -o-transition     : background 0.4s ease-in-out;
      transition        : background 0.4s ease-in-out;

      &.active:hover {
          background: #eef3f7 !important;
      }
  }

  .ant-list-item-meta-title {
      margin-bottom: 4px;
  }

  .ant-list-item-meta-description {
      .ant-badge .ant-badge-dot {
          top: 4px;
      }
  }

  .ant-progress {
      position: relative;
      top     : -5px;
  }
}

.leaflet-container {
  z-index: 1;
  height : 600px;
  width  : 100%;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px !important;

  .leaflet-popup-content {
      min-width: 100px;
      margin   : 20px 20px 20px 14px !important;

      .bold {
          font-weight: 600;
      }

      p {
          margin: 10px 0 !important;
      }

      tag {
          background   : #E8F5FF;
          padding      : 4px 10px;
          border-radius: 4px;
      }

      a {
          margin-left: 30px;
      }
  }
}

.map-marker {
  font-size: 40px;
}

.ant-form-item-label {
  line-height: 28px !important;
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-form-item-control {
  @media #{$information-phone-mobile} {
      line-height: 20px !important;
  }
}

.ant-calendar-picker {
  text-align: left;
}

.avatar-mini-icon {
  vertical-align: sub !important;
  box-shadow    : 0 3px 3px 0 rgba(46, 46, 46, 0.25);

  i {
      font-size: 20px;
      position : relative;
      top      : 2px;
  }
}

.avatar-reg-icon {
  vertical-align: sub !important;
  box-shadow    : 0 3px 3px 0 rgba(46, 46, 46, 0.25);

  i {
      font-size: 20px;
      position : relative;
      top      : 4px;
  }
}

.avatar-square {
  height  : 28px !important;
  width   : 28px !important;
  position: relative;
  top     : -2px;

  i {
      font-size     : 22px;
      vertical-align: text-bottom;
  }

  &.ant-avatar-sm {
      height: 22px !important;
      width : 22px !important;

      i {
          font-size     : 18px;
          vertical-align: text-top;
      }
  }
}

.ant-badge-not-a-wrapper {
  vertical-align: baseline !important;
}

.ant-scroll-number-only>p {
  margin-bottom: 0 !important; // Default ANT style for badge count
}

.stat-unit,
.stat-unit-left,
.stat-unit-right {
  color    : $grey-dark;
  font-size: 18px;
  position : relative;
  top      : -1px;
}

.stat-unit-left {
  right: 2px;
}

.stat-unit-right {
  left: 2px;
}

.slide-card-form {
  max-height        : 0;
  overflow-y        : hidden;
  -webkit-transition: max-height 0.4s ease-in-out;
  -moz-transition   : max-height 0.4s ease-in-out;
  -o-transition     : max-height 0.4s ease-in-out;
  transition        : max-height 0.4s ease-in-out;
}

.slidedown {
  max-height: 60px;
}

ul.mini-card-details {
  padding-left: 16px;

  li:before {
      // color    : $grey-dark;
      // font-size: 18px;
  }

  li {
      color: $grey-dark;
  }
}

.location-basic-details-padded {
  padding-left: 50px !important;

  @media #{$information-tab} {
      padding-left: 12px !important;
  }

  @media #{$information-phone-mobile} {
      padding-left: 12px !important;
  }
}

.location-details-stats {
  p {
      word-break: break-word;
  }
}

.zone-desc-linked-list {
  .ant-list-item {
      .ant-list-item-meta-description {
          position: relative;

          .ant-tag {
              position: absolute;
              left    : 50%;
          }
      }
  }
}

.page-tabs {
  .ant-tabs-bar {
      border-top   : 1px solid #e8e8e8;
      border-bottom: none;
      font-weight  : 500;
      margin       : 0 0 24px 0;

      .ant-tabs-nav .ant-tabs-tab-active {
          font-weight: 500;
      }
  }

  .ant-tabs-nav-wrap {
      font-size: large;
      padding  : 15px 26px;
  }
}

.card-tabs {
  background: $white;

  .ant-tabs-tab {
      padding: 10px 16px !important;
  }

  .ant-tabs-bar {
      border-top   : none !important;
      border-bottom: 1px solid #e8e8e8 !important;
      margin       : 0 !important;
  }

  .ant-tabs-nav-wrap {
      padding: 0 !important;
  }

  .ant-tabs-extra-content {
      padding: 9px 24px;
  }

  .ant-tabs-tabpane {
      padding: 24px;
  }
}

.user-role-list-filters,
.user-role-detail-filters,
.user-list-filters,
.charity-filters,
.contacts-filters,
.location-detail-filters,
.location-list-filters {
  >span {
      display: inline-block;
  }
}

.reports-filter {
  background: $blue-light;
  padding   : 16px 24px;

  >span {
      margin-top   : 4px;
      margin-bottom: 4px;
      display      : inline-block;
  }
}

.report-data-compare {
  padding   : 5px 0;
  background: $blue-light;
}

.chart-tabular-legends {
  width : 80%;
  margin: 0 auto;

  tr {

      td {
          color      : $grey-dark;
          font-size  : 14px;
          font-weight: 500;

          &:last-child {
              text-align: right;
          }
      }
  }
}

.highcharts-exporting-group {
  display: none;
}

.highcharts-title {
  width: 180px !important
}

.vertical-chart-legend {
  width: 200px;

  span {
      &:first-child {
          width        : 150px;
          white-space  : nowrap;
          overflow     : hidden;
          text-overflow: ellipsis;
      }

      &:last-child {
          text-align: right;
          width     : 50px;
          float     : right;
      }
  }
}

.game-terminal-stat-card {
  height       : 172px;
  border-radius: 2px;
  text-align   : center;
  padding      : 14px;

  h2 {
      font-size    : 60px;
      margin-bottom: 10px;
      font-variant : normal;
  }

  p {
      font-variant: normal;
      font-size   : 18px;
      font-weight : 500;
      color       : $grey-darker;
  }

  &.danger {
      background: rgba(215, 86, 86, 0.2);
      border    : 1px solid #F7B0AD;

      h2 {
          color: #F46057;
      }
  }

  &.success {
      border          : 1px solid #68C172;
      background-color: rgba(126, 211, 33, 0.2);

      h2 {
          color: #68C172;
      }
  }

  &.warning {
      border          : 1px solid #F8CA1C;
      background-color: rgba(248, 202, 28, 0.2);

      h2 {
          color: #F8CA1C;
      }
  }
}

#gridContainer {
  min-height: 400px;

  .dx-toolbar {
    display: none;
  }

  .dx-header-row {
      td {
          background        : #fafafa;
          -webkit-transition: background .3s ease;
          transition        : background .3s ease;
          color             : $black;
          font-weight       : 500;
          border-bottom     : 1px solid #e8e8e8;
          font-size         : 14px;
      }
  }

  .dx-datagrid .dx-row>td {
      padding: 12px 5px;

      &.dx-master-detail-cell {
          padding: 6px 0;
      }
  }

  .dx-datagrid .dx-column-lines>td {
      border-left : none !important;
      border-right: none !important;
  }

  .dx-master-detail-row {
      .dx-datagrid-headers {
          td {
              font-size: 12px;
              padding  : 6px 5px;
          }
      }

      .dx-datagrid.dx-gridbase-container {
          min-height: 200px;
      }
  }

  // .dx-datagrid-action {
  //     position: relative;
  // }
  .dx-column-indicators {
      // position: absolute;
      // left    : 15%;
      float      : none !important;
  }
}

.iframe {
  width     : 100%;
  min-height: 300px !important;
}

.text-negative {
  color: $danger;
}

.text-positive {
  color: green;
}

.text-nowrap {
  white-space: nowrap;
}

.float-right {
  float: right;
}

.summary-list {
  text-align: right;
  font-size: larger;

  .ant-row {
      .ant-col:first-child() {
          font-weight: bold;
      }
  }

}

@media #{$information-laptop} {
  .lg-row-b-margin {
      margin-bottom: 24px;
  }

  .location-list-filters,
  .location-detail-filters {
      >span {
          margin-top   : 4px;
          margin-bottom: 4px;
      }
  }

  .zone-list-filters {
      >span {
          display      : block;
          margin-top   : 4px;
          margin-bottom: 4px;
      }
  }
}

@media #{$information-tab} {

  .user-role-list-filters,
  .user-role-detail-filters,
  .user-list-filters,
  .charity-filters,
  .contacts-filters,
  .location-list-filters,
  .location-detail-filters {
      >span {
          margin-top   : 4px;
          margin-bottom: 4px;
      }
  }

  .ant-card-head-title {
      overflow: visible;
  }

  .md-row-b-margin {
      margin-bottom: 24px;
  }

}

@media #{$information-phone-mobile} {

  .user-role-list-filters,
  .user-role-detail-filters,
  .user-list-filters,
  .charity-filters,
  .contacts-filters,
  .location-list-filters,
  .location-detail-filters {
      >span {
          margin-top   : 4px;
          margin-bottom: 4px;
      }
  }

  .sm-row-b-margin {
      margin-bottom: 24px;
  }

  .hidden-sm {
      display: none;
  }

  .ant-card-head-title {
      padding: 12px 0 0 0 !important;
  }

  .ant-card-extra {
      padding   : 0 0 12px 0 !important;
      float     : none;
      text-align: left;
  }

  .editable {
      font-family: sans-serif;
      margin-left: 10%;
      width: 75%;
      min-height: 100px;
      border: 1px solid black;
      padding: 5px;
      resize: none;
}
}

.generate-invoices-results {
.ant-collapse-item {
  border-bottom: 0px !important;
}

.ant-collapse-header {
  padding-left: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
}
.remaining-stamps {
border: none;
}
.remaining-stamps tbody
{
border: none;
}
.remaining-stamps .ant-table-small .ant-table-body
{
margin: 0 0;
}
.remaining-stamps .ant-table-small .ant-table-body .ant-table-row-cell-break-word, .remaining-stamps .ant-table-small
{
border: none;
}
.remaining-stamps .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td
{
padding: 2px;
font-size: 12px;
}
.remaining-stamps .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
background: unset;
}
.remaining-stamps-header.ant-card .ant-card-head-wrapper .ant-card-head-title
{
font-size: 14px;
padding: 2px 0 2px 0;
}
.remaining-stamps-header.ant-card .ant-card-head
{
min-height: 20px;
border: 0;
}
.remaining-stamps-header .ant-card-body
{
padding: 2px 24px 2px 24px !important;
}
.distributor-details.ant-row-flex
{
background-color: #fff;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #D41C33;
  --amplify-components-button-primary-hover-background-color: #222328;
  --amplify-components-button-primary-hover-border-color: #D41C33;
}
